<template>
  <FwbModal v-if="showModal" @close="emit('update:showModal', false)">
    <template #header>
      <FwbHeading tag="h5" class="text-center">
        {{ $t("challenge_friends") }}
      </FwbHeading>
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <FwbP> {{ $t("challenge_friends_email") }}: </FwbP>
          <FwbTextarea
            v-model="v.textfield.$model"
            :placeholder="$t('challenge_friends_placeholder')"
          />
          <ul class="list-disc text-red-500 px-4">
            <li v-for="(error, i) in v.textfield.$errors" :key="i">
              {{ error.$message }}
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <ul
        v-if="challengedUserEmails?.length"
        class="flex flex-col gap-2 text-send-purple-1"
      >
        <li
          v-for="email in challengedUserEmails"
          :key="email"
          class="before:content-['✓'] flex gap-2 items-center"
        >
          <FwbP>
            {{ $t("challenge_create.challenge_user", { user: email }) }}
          </FwbP>
        </li>
      </ul>
      <FwbP class="text-send-grey-80" size="sm">
        {{ $t("challenge_friends_subtext") }}
      </FwbP>

      <FwbButton
        type="button"
        color="dark"
        class="mt-4"
        :disabled="v.$invalid"
        @click="save"
      >
        {{ $t("done") }}
      </FwbButton>
    </template>
  </FwbModal>
</template>

<script lang="ts" setup>
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import FwbModal from "~/components/Flowbite/FwbModal/FwbModal.vue";
import FwbTextarea from "~/components/Flowbite/FwbTextarea/FwbTextarea.vue";
import FwbP from "~/components/Flowbite/Typography/FwbP.vue";
import { useCreateChallengeStore } from "~/stores/createChallenge";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import FwbHeading from "~/components/Flowbite/Typography/FwbHeading.vue";

defineProps<{
  showModal: boolean;
}>();

const { verifyingUser } = toRefs(useAuthStore());

const mustNotContainCreatorEmail = (value: string) => {
  if (!value || !verifyingUser.value || !verifyingUser.value.email) return true;

  value = value.toLowerCase();

  return !value.includes(verifyingUser.value.email.toLowerCase());
};

const store = useCreateChallengeStore();
const { challengedUserEmailsString } = toRefs(store);
const state = reactive({
  textfield: challengedUserEmailsString.value,
  error: false,
});
const { t } = useI18n();

// TODO: in the future, we should support email as well as a user object
const challengedUserEmails = computed(() => {
  if (!challengedUserEmailsString.value) return null;
  return getEmailsFromText(state.textfield ?? "");
});

const v = useVuelidate(
  {
    textfield: {
      required,
      containsEmails: helpers.withMessage(
        t("create_challenge_page.validate_email"),
        (value: string) => {
          return !!(
            !value ||
            (value && getEmailsFromText(value ?? "").length > 0)
          );
        }
      ),
      mustNotContainCreatorEmail: helpers.withMessage(
        t("create_challenge_page.validate_contains_creator_email"),
        mustNotContainCreatorEmail
      ),
    },
  },
  state
);

const emit = defineEmits(["update:showModal"]);

const save = async () => {
  challengedUserEmailsString.value = state.textfield;
  emit("update:showModal", false);
};
</script>
